<template>
  <div class="main_container">
    <!-- Ligne n°1 -->
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Rapport sur les rédevance Pesage</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Rapport sur les redevances de pesage
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <!-- Fin ligne n°1 -->
    <!-- Ligne n°2 -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="Rapport_patente_vignette"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="landscape"
                  pdf-content-width="1000px"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"> 
      <section slot="pdf-content">
        
      </section> 
    </vue-html2pdf>
    <!-- END of Vue-html2pdf  -->
    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-md-4 mx-auto mt-3">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisissez la gare</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="gare"
                    @change="changeGare(gare)">
              <option v-for="(gare,garekey) in listGare"
                      :key="garekey"
                      :value="gare">{{gare}}</option>
              <!-- <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
              <option value="MOAPE">MOAPE</option> -->
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 mx-auto mt-3">
        <div class="form-row row">
          <div class="form-group col-md-6"><label for="">Choisissez un axe</label></div>
          <div class="form-group col-md-6">
            <select class="form-control"
                    name=""
                    id=""
                    v-model="axe"
                    @change="changeAxe(axe)">
              <option v-for="(axe,axekey) in listAxe"
                      :key="axekey"
                      :value="axe">{{axe}}</option>
              <!-- <option value="SINGROBO">SINGROBO</option>
              <option value="THOMASSET">THOMASSET</option>
              <option value="MOAPE">MOAPE</option> -->
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-5"
         v-if="yearData == null">
      <div class="col-md-10 mx-auto">
        <h4 class="text-center">
          Veuillez sélectionner une période contenant des données.
        </h4>
      </div>
    </div>
    <div class="row mt-4 mb-5"
         v-if="yearData != null">
      <div class="text-center mb-5 mt-3"
           :class="width"
           style="height:300px;"
           v-if="gareData != null">
        <h5>Recettes des gares de peage par mois</h5>
        <LineGenerator :chartdata="chartDataLineChart "
                       :height="270"></LineGenerator>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && gare!='TOUTES'">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          Le mois le plus rentable de la gare de {{ gare }} de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMax.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.pourcentage }}</strong> % de la recette annuelle ({{ monthTotal.toLocaleString() }} FCFA).
        </p>
        <p>
          Le mois le moins rentable de la gare de {{ gare }} de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMin.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.pourcentage }}</strong> % de la recette annuelle ({{ monthTotal.toLocaleString() }} FCFA).
        </p>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && gare=='TOUTES'">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          Le mois le plus rentable de {{ gare }} les gares de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMax.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMax.pourcentage }}</strong> % de la recette annuelle ({{ totalAnnee.toLocaleString() }} FCFA).
        </p>
        <p>
          Le mois le moins rentable de la gare de {{ gare }} de l'année {{ annee_debut.split("-")[0] }} est le mois de 
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.mois }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(moisMin.montant).toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ moisMin.pourcentage }}</strong> % de la recette annuelle ({{ totalAnnee.toLocaleString() }} FCFA).
        </p>
      </div>
    </div>
    <div class="row mt-5"
         style="background-color: #b1dbe0;min-height: 450px;"
         v-if="yearData != null">
      <div class="text-center mb-5 mt-3"
           :class="width"
           style="height:300px;"
           v-if="yearData != null">
        <h5>Recettes des gares de peage</h5>    
        <Pie :chartdata="chartDataPieChart"
             :height="270"></Pie>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && (gare=='TOUTES' || gare=='CONSOLIDEE')">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La gare la plus rentable de l'année {{ annee_debut.split("-")[0] }} est la gare de 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMax.gare }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMax.montant }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMax.pourcentage }}</strong> % de la recette annuelle ({{ total.recette.toLocaleString() }} FCFA).
        </p>
        <p>
          La gare la moins rentable de l'année {{ annee_debut.split("-")[0] }} est la gare de 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMin.gare }}</strong> avec 
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMin.montant }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ gareMin.pourcentage }}</strong> % de la recette annuelle ({{ total.recette.toLocaleString() }} FCFA).
        </p>
      </div>
      <div class="col-md-6 mb-5 mt-3"
           v-if="yearData != null && gare!='TOUTES'&& gare!='CONSOLIDEE'">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La gare  
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.gare }}</strong> 
          se place à la  
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.rang }}<sup>{{ garePosition.rang!=1?"ème":"ère" }}</sup></strong> 
          place pour cette année {{ annee_debut.split("-")[0] }} avec
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.recette.toLocaleString() }}</strong> FCFA de recette soit
          <strong style="color: #0097a9;font-weight: bold;">{{ garePosition.pourcentage }}</strong> % de la recette annuelle ({{ total.recette.toLocaleString() }} FCFA).
        </p>
      </div>
    </div>
    <div class="row mt-5"
         v-if="yearData != null">
      <div class="text-center mb-5 mt-3 col-md-7"
           v-if="gare!='TOUTES' && gare!=null && gare!=''"
           style="height:300px;">
        <h5>Recettes des gares de péage par nature</h5>    
        <BarChart :chartdata="chartDataBarChart"
                  :height="270"
                  v-if="dataLoaded"></BarChart>
      </div>
      <div class="text-center mb-5 mt-3 col-md-7"
           v-if="gare=='TOUTES' || axe!=null && axe!=''"
           style="height:300px;">
        <h5>Recettes des gares de péage par nature</h5>    
        <BarChart :chartdata="chartDataBarChart2"
                  :height="470"
                  :options="optionBarChart"
                  v-if="heavyDataLoaded"></BarChart>
      </div>
      <div class="col-md-5 mb-5 mt-3"
           v-if="gare!='TOUTES' && gare!=null && gare!=''">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La nature de recette la plus rentable de la gare <strong style="color: #0097a9;font-weight: bold;">{{ gare }}</strong> 
          est la nature 
          <strong style="color: #0097a9;font-weight: bold;">
            {{ 
              totalNatureRecette.position[0].nature == "amende_pesage"?"amende de pesage":
              totalNatureRecette.position[0].nature == "frais_pesee"?"frais de pesée":"pesée de cimentier" 
            }}
          </strong>
          avec <strong style="color: #0097a9;font-weight: bold;">{{ Number(totalNatureRecette.position[0].montant).toLocaleString() }}</strong> FCFA de recette annuelle contre
          <strong style="color: #0097a9;font-weight: bold;">
            {{ Number(totalNatureRecette.position[1].montant).toLocaleString() }}
          </strong> FCFA pour les
          <strong style="color: #0097a9;font-weight: bold;">
            {{ 
              totalNatureRecette.position[1].nature == "amende_pesage"?"amende de pesage":
              totalNatureRecette.position[1].nature == "frais_pesee"?"frais de pesée":"pesée de cimentier" 
            }}
          </strong> et 
          <strong style="color: #0097a9;font-weight: bold;">
            {{ Number(totalNatureRecette.position[2].montant).toLocaleString() }}
          </strong> FCFA pour les
          <strong style="color: #0097a9;font-weight: bold;">
            {{ 
              totalNatureRecette.position[2].nature == "amende_pesage"?"amende de pesage":
              totalNatureRecette.position[2].nature == "frais_pesee"?"frais de pesée":"pesée de cimentier" 
            }}
          </strong>. <br>
          Cette nature s'illustre avec une valeur maximale de 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMax.montant.toLocaleString() }}</strong> FCFA en 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMax.mois }}</strong> et une valeur minimale de 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMin.montant.toLocaleString() }}</strong> FCFA en 
          <strong style="color: #0097a9;font-weight: bold;">{{ totalNatureRecette.moisMin.mois }}</strong>
        </p>
      </div>
      <div class="col-md-5 mb-5 mt-3"
           v-if="gare=='TOUTES' || axe!=null && axe!=''">
        <h5 style="color: #0097a9;font-weight: bold;"
            class="text-center">Analyse</h5>
        <p>
          La gare qui rapporte le plus 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong> 
          en amende sur pesage est la gare <strong style="color: #0097a9;font-weight: bold;">{{ amende_pesage.max.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(amende_pesage.max.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
          La gare qui rapporte le moins 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong>
          en amende sur pesage est la gare <strong style="color: #0097a9;font-weight: bold;">{{ amende_pesage.min.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(amende_pesage.min.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
        </p>
        <p>
          La gare qui rapporte le plus 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong> 
          en frais de pesée est la gare <strong style="color: #0097a9;font-weight: bold;">{{ frais_pesee.max.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(frais_pesee.max.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
          La gare qui rapporte le moins 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong>
          en frais de pesée est la gare <strong style="color: #0097a9;font-weight: bold;">{{ frais_pesee.min.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(frais_pesee.min.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
        </p>
        <p>
          La gare qui rapporte le plus
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong>
          en pesée sur cimentier est la gare <strong style="color: #0097a9;font-weight: bold;">{{ pesee_cimentier.max.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(pesee_cimentier.max.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
          La gare qui rapporte le moins 
          <strong style="color: #0097a9;font-weight: bold;">{{ axe==""||axe==null?"de toutes les gares":"de l'axe "+axe }}</strong>
          en pesée sur cimentier est la gare <strong style="color: #0097a9;font-weight: bold;">{{ pesee_cimentier.min.gare }}</strong>  
          avec un montant de 
          <strong style="color: #0097a9;font-weight: bold;">{{ Number(pesee_cimentier.min.montant).toLocaleString() }}</strong> FCFA de recette annuelle.
        </p>
      </div>
    </div>
  </div>
</template>
<style>

form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
.btn-info,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search,
.table-blue{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import VueHtml2pdf from "vue-html2pdf"
import BarChart from "@/components/shared/BarChart"
import Pie from "../../shared/PieChart"
import LineGenerator from "../../shared/LineChart"
export default {
  name: "RapportRedevancePesage",
  components: {
    VueHtml2pdf,
    BarChart,
    Pie,
    LineGenerator,
  },

  data: () => ({
    heavyDataLoaded:false,
    dataLoaded:false,
    chartDataLineChart: {
      labels: [],
      datasets: [
        {
          label: "Recette pesage ",
          data: [],
          backgroundColor: [],
          hoverOffset: 4,
        },
      ],
    },
    optionLineChart:{
      legend: {
        labels: {
          fontColor: '#ffffff'
        }
      }
    },
    optionBarChart:{
      scales:{
        x:{
          stacked:true,
        },
        y:{
          stacked:true,
        },
      }
    },
    chartDataPieChart: {
      labels: [],
      datasets: [
        {
          label: "Recette pesage par gare (%)",
          data: [],
          backgroundColor: [],
          hoverOffset: 4,
        },
      ],
    },
    chartDataBarChart: {labels: [], datasets: []},
    chartDataBarChart2: {labels: [], datasets: []},
    width: "col-md-6",
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year: {
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",  
    },
    color:"",
    yearData: null,
    month: [
      { mois: "Janv.", total: 0 },
      { mois: "Fev.", total: 0 },
      { mois: "Mars", total: 0 },
      { mois: "Avr.", total: 0 },
      { mois: "Mai.", total: 0 },
      { mois: "Juin", total: 0 },
      { mois: "Juil.", total: 0 },
      { mois: "Août.", total: 0 },
      { mois: "Sept.", total: 0 },
      { mois: "Oct.", total: 0 },
      { mois: "Nov.", total: 0 },
      { mois: "Dec.", total: 0 },
    ],
    monthTotal: 0,
    gareData: null,
    gare:"ABENGOUROU",
    axe:"",
    listAxe:[
      "Recettes de pesage autoroute du nord",
      "Recettes de pesage de Thomasset et de Moapé",
      "Recettes de pesage autoroute du sud",
      "TOUS"
    ],
    listGare:[],
    total: {
      recette:0,
      amende_pesage:0,
      frais_pesee:0,
      pesee_cimentier:0,
    },
    moisMax:{
      mois:"",
      montant:0,
      pourcentage:""
    },
    moisMin:{
      mois:"",
      montant:0,
      pourcentage:""
    },
    gareMax:{
      gare:"",
      montant:0,
      pourcentage:""
    },
    gareMin:{
      gare:"",
      montant:0,
      pourcentage:""
    },
    amende_pesage:{
      max:{
        montant:0,
        gare:0,
      },
      min:{
        montant:0,
        gare:0,
      },
    },
    frais_pesee:{
      max:{
        montant:0,
        gare:0,
      },
      min:{
        montant:0,
        gare:0,
      },
    },
    pesee_cimentier:{
      max:{
        montant:0,
        gare:0,
      },
      min:{
        montant:0,
        gare:0,
      },
    },
    totalAnnee:0,
    totalNatureRecette:{
      position:[
        {nature:"amende_pesage",montant:0},
        {nature:"frais_pesee",montant:0},
        {nature:"pesee_cimentier",montant:0},
      ],
      moisMax:{montant:0,mois:""},
      moisMin:{montant:0,mois:""},
      pourcentage:"",
    },
    gareRanking:[],
    garePosition:"",
    tableauCouleurs: ["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"],
  }),
  created() {
    this.RedevancePesage(this.year)
    this.RecettePesageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, gare: this.gare })

    if (window.innerWidth > 1400) {
      this.width = "col-md-4 mx-auto"
    }
  },
  watch: {
    recettePesageG() {
      if (this.recettePesageG["tableau"]) {
        if (this.gare!=null && this.gare!="") {
          if (this.gare !="TOUTES") {
            this.gareData=null
            this.gareData = this.recettePesageG["tableau"][0]
            this.monthTotal = 0
            // initialisation de l'objet d'analyse des recettes par nature
            this.totalNatureRecette={
              position:[
                {nature:"amende_pesage",montant:this.gareData.totaux.amende_pesage},
                {nature:"frais_pesee",montant:this.gareData.totaux.frais_pesee},
                {nature:"pesee_cimentier",montant:this.gareData.totaux.pesee_cimentier},
              ],
              moisMax:{montant:0,mois:""},
              moisMin:{montant:0,mois:""},
              pourcentage:"",
            }
            console.log(this.chartDataLineChart)
            this.chartDataBarChart = {
              labels: [],
              datasets: [
                {
                  label: "Amende de pesage",
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                },
                {
                  label: "Frais de pesée",
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                },
                {
                  label: "Pesée de cimentier",
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                }
              ]
            }
            // console.log(this.chartDataBarChart) 
            for (let index = 0; index < this.month.length; index++) {
              this.month[index].total =
                this.gareData.amende_pesage[index] +
                this.gareData.frais_pesee[index] +
                this.gareData.pesee_cimentier[index]

              this.monthTotal += this.month[index].total
              // Remplissage des données du lineChart
              this.chartDataLineChart.labels.push(this.month[index].mois)
              this.chartDataLineChart.datasets[0].data.push(this.month[index].total)
              
              // Remplissage des données du BarChart
              this.chartDataBarChart.labels.push(this.month[index].mois)
              this.chartDataBarChart.datasets[0].data.push(this.gareData.amende_pesage[index])
              this.chartDataBarChart.datasets[1].data.push(this.gareData.frais_pesee[index])
              this.chartDataBarChart.datasets[2].data.push(this.gareData.pesee_cimentier[index])
              this.chartDataBarChart.datasets[0].backgroundColor.push("#4141d9")
              this.chartDataBarChart.datasets[1].backgroundColor.push("#d94144")
              this.chartDataBarChart.datasets[2].backgroundColor.push("#00B5CB")
            }
            // Determination de la nature de recette la plus rentable
            var natureRentable=""
            
            for (let index = 0; index < this.totalNatureRecette.position.length-1; index++) {
              for (let jindex = 1; jindex < this.totalNatureRecette.position.length; jindex++) {
                if (this.totalNatureRecette.position[index].montant<this.totalNatureRecette.position[jindex].montant) {
                  natureRentable=this.totalNatureRecette.position[index]
                  this.totalNatureRecette.position[index] = this.totalNatureRecette.position[jindex]
                  this.totalNatureRecette.position[jindex] = natureRentable
                }
              } 
            }
            console.log(this.totalNatureRecette.position)
            
            // Attribution des couleurs aux Charts
            this.chartDataLineChart.datasets[0].backgroundColor.push("#FF8A80")
            this.chartDataBarChart.datasets[0].backgroundColor.push("#4141d9")
            this.chartDataBarChart.datasets[1].backgroundColor.push("#d94144")
            this.chartDataBarChart.datasets[2].backgroundColor.push("#4141d9")
            this.chartDataLineChart.datasets[0].label += this.gare
            this.dataLoaded=true

            console.log(this.chartDataLineChart)
            // Initialisation des objets pour obtenir le mois le plus rentable et le mois le moins rentable d'une gare
            this.moisMax.mois=this.month[0].mois
            this.moisMax.montant=this.month[0].total
            this.moisMin.mois=this.month[0].mois
            this.moisMin.montant=this.month[0].total
            // initialisation du mois le plus rentable et du mois le moins rentable de la nature la plus rentable
            this.totalNatureRecette.moisMax.montant = this.gareData[this.totalNatureRecette.position[0].nature][0]
            this.totalNatureRecette.moisMax.mois = this.month[0].mois
            this.totalNatureRecette.moisMin.montant = this.gareData[this.totalNatureRecette.position[2].nature][0]
            this.totalNatureRecette.moisMin.mois = this.month[0].mois
            // Boucle pour déterminer le le mois le plus rentable et le mois le moins rentable d'une gare
            for (let index = 0; index < this.month.length; index++) {
              if (this.month[index].total > this.moisMax.montant) {//Determination du mois le plus rentable
                this.moisMax.montant=this.month[index].total
                this.moisMax.mois=this.month[index].mois
              }
              if (this.month[index].total < this.moisMin.montant) {//Determination du mois le moins rentable
                this.moisMin.montant=this.month[index].total
                this.moisMin.mois=this.month[index].mois
              }
              //Determination du mois le plus rentable et le mois le moins rentable de la nature la plus rentable
              if (this.totalNatureRecette.moisMax.montant < this.gareData[this.totalNatureRecette.position[0].nature][index]) {
                this.totalNatureRecette.moisMax.montant = this.gareData[this.totalNatureRecette.position[0].nature][index]
                this.totalNatureRecette.moisMax.mois = this.month[index].mois
              }
              if (this.totalNatureRecette.moisMin.montant > this.gareData[this.totalNatureRecette.position[2].nature][index]) {
                this.totalNatureRecette.moisMin.montant = this.gareData[this.totalNatureRecette.position[2].nature][index]
                this.totalNatureRecette.moisMin.mois = this.month[index].mois
              }
            }
            this.moisMax.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le plus rentable
              ((this.moisMax.montant*100)/this.monthTotal) 
                * 100) / 100
            this.moisMin.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le moins rentable
              ((this.moisMin.montant*100)/this.monthTotal) 
                * 100) / 100
            this.totalNatureRecette.pourcentage= this.totalNatureRecette.position[0]
            // console.log(this.totalNatureRecette)
          }else{
            this.gareData=null
            this.gareData = this.recettePesageG["tableau"]
            this.monthTotal = []
            this.month= [
              { mois: "Janv.", total:[0]},
              { mois: "Fev.", total:[0]},
              { mois: "Mars", total:[0]},
              { mois: "Avr.", total:[0]},
              { mois: "Mai.", total:[0]},
              { mois: "Juin", total:[0]},
              { mois: "Juil.", total:[0]},
              { mois: "Août.", total:[0]},
              { mois: "Sept.", total:[0]},
              { mois: "Oct.", total:[0]},
              { mois: "Nov.", total:[0]},
              { mois: "Dec.", total:[0]}
            ]
            this.moisMax.mois=this.month[0].mois
            this.moisMax.montant=this.month[0].total
            this.moisMin.mois=this.month[0].mois
            this.moisMin.montant=this.month[0].total
            this.chartDataLineChart={
              labels: [],
              datasets: [],
            }
            var color=[
              "#083291",
              "#910856",
              "#910818",
              "#917a08",
              "#c4b3f5",
              "#a829e3",
              "#292fe3",
              "#29b5e3",
              "#29e392",
              "#1e3b2e",
              "#3b2a1e",
              "#331e3b",
              "#806ca1",
              "#6ca19d",
              "#74a16c",
              "#a1816c",
              "#f2b8b6",
              "#b6f2b8",
              "#b3e6f5",
              "#911108",
              "#fa6f05",
              "#fafa05",
              "#63fa05",
            ]
            this.chartDataBarChart2 = {labels: [], datasets: []}

            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              this.monthTotal.push(0)
              this.chartDataLineChart.datasets.push({
                label: "Recette pesage "+this.gareData[pointer].gare,
                data: [],
                backgroundColor: [],
                borderColor:"",
                fill: false,
                hoverOffset: 4,
              })
              this.chartDataBarChart2.datasets.push(
                {
                  label: "Amende de pesage "+this.gareData[pointer].gare,
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                  // stack:'Stack '+pointer,
                },
                {
                  label: "Frais de pesée "+this.gareData[pointer].gare,
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                  // stack:'Stack '+pointer,
                },
                {
                  label: "Pesée cimentier "+this.gareData[pointer].gare,
                  data: [],
                  backgroundColor: [],
                  hoverOffset: 4,
                  // stack:'Stack '+pointer,
                }
              )
            }

            this.monthTotal.push(0)  
            
            for (let index = 0; index < this.month.length; index++) {
              for (let pointer = 0; pointer < this.gareData.length; pointer++) {
                this.month[index].total[pointer]=0
              } 
              this.month[index].total.push(0)
              this.chartDataLineChart.labels.push(this.month[index].mois)
              this.chartDataBarChart2.labels.push(this.month[index].mois)
            }

            var position=0
            var totalGareNature=[]
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              totalGareNature.push({
                gare:this.gareData[pointer].gare,
                amende_pesage:0,
                frais_pesee:0,
                pesee_cimentier:0,
              })
              for (let index = 0; index < this.month.length; index++) {
                this.month[index].total[pointer]+=
                  this.gareData[pointer].pesee_cimentier[index] +
                  this.gareData[pointer].frais_pesee[index] +
                  this.gareData[pointer].amende_pesage[index] //+

                this.chartDataLineChart.datasets[pointer].data[index]=
                  this.gareData[pointer].pesee_cimentier[index] +
                  this.gareData[pointer].frais_pesee[index] +
                  this.gareData[pointer].amende_pesage[index]

                // TOTAL de toutes les gares par mois
                this.month[index].total[this.gareData.length]+=
                  this.gareData[pointer].pesee_cimentier[index] +
                  this.gareData[pointer].frais_pesee[index] +
                  this.gareData[pointer].amende_pesage[index]
                //Total de toutes les nature par gare
                totalGareNature[pointer].amende_pesage+=this.gareData[pointer].amende_pesage[index]
                totalGareNature[pointer].frais_pesee+=this.gareData[pointer].frais_pesee[index]
                totalGareNature[pointer].pesee_cimentier+=this.gareData[pointer].pesee_cimentier[index]
                if (position+1<this.gareData.length) {
                  this.chartDataBarChart2.datasets[position].data[index]=this.gareData[pointer].amende_pesage[index]
                  this.chartDataBarChart2.datasets[position+1].data[index]=this.gareData[pointer].frais_pesee[index]
                  this.chartDataBarChart2.datasets[position+2].data[index]=this.gareData[pointer].pesee_cimentier[index]
                  this.chartDataBarChart2.datasets[position].backgroundColor.push(color[pointer])
                  this.chartDataBarChart2.datasets[position+1].backgroundColor.push(color[pointer])
                  this.chartDataBarChart2.datasets[position+2].backgroundColor.push(color[pointer])
                }
                    
                this.monthTotal[pointer] += this.month[index].total[pointer]
                //TOTAL DES TOTAUX DE TOUS LES MOIS PAR GARE
                this.monthTotal[this.gareData.length] += this.month[index].total[pointer]
                this.chartDataLineChart.datasets[pointer].backgroundColor.push(color[pointer])
              }
              this.chartDataLineChart.datasets[pointer].borderColor = color[pointer]
              console.log(this.chartDataLineChart)
              position=position+3
              this.heavyDataLoaded=true
            }
            
            // Initialisation des objets pour obtenir le mois le plus rentable et le mois le moins rentable d'une gare
            var totalGareMois=[]
            this.totalAnnee=0
            for (let index = 0; index < this.month.length; index++) {
              totalGareMois.push({mois:this.month[index].mois,montant:0})
              var totalMois = 0
              for (let jindex = 0; jindex < this.month[index].total.length-1; jindex++) {
                totalMois += this.month[index].total[jindex]
                this.totalAnnee += this.month[index].total[jindex]
              }
              totalGareMois[index].montant=totalMois
            }
            // console.log(totalGareMois)
            this.moisMax.mois = totalGareMois[0].mois
            this.moisMax.montant = totalGareMois[0].montant
            this.moisMin.mois = totalGareMois[0].mois
            this.moisMin.montant = totalGareMois[0].montant
            
            for (let index = 0; index < totalGareMois.length; index++) {

              if (totalGareMois[index].montant > this.moisMax.montant) {//Determination du mois le plus rentable
                this.moisMax.montant=totalGareMois[index].montant
                this.moisMax.mois=totalGareMois[index].mois
              }
              if (totalGareMois[index].montant < this.moisMin.montant) {//Determination du mois le moins rentable
                this.moisMin.montant=totalGareMois[index].montant
                this.moisMin.mois=totalGareMois[index].mois
              }
            }
            this.moisMax.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le plus rentable
              ((this.moisMax.montant*100)/this.totalAnnee) 
                * 100) / 100
            this.moisMin.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le moins rentable
              ((this.moisMin.montant*100)/this.totalAnnee) 
                * 100) / 100

            // Determination de la gare qui rapporte le plus par carte
            this.amende_pesage={//initialisation de l'objet
              max:{
                montant: totalGareNature[0].amende_pesage,
                gare:totalGareNature[0].gare,
              },
              min:{
                montant: totalGareNature[0].amende_pesage,
                gare:totalGareNature[0].gare,
              },
            }
            this.frais_pesee={//initialisation de l'objet
              max:{
                montant: totalGareNature[0].frais_pesee,
                gare:totalGareNature[0].gare,
              },
              min:{
                montant: totalGareNature[0].frais_pesee,
                gare:totalGareNature[0].gare,
              },
            }
            this.pesee_cimentier={//initialisation de l'objet
              max:{
                montant: totalGareNature[0].pesee_cimentier,
                gare:totalGareNature[0].gare,
              },
              min:{
                montant: totalGareNature[0].pesee_cimentier,
                gare:totalGareNature[0].gare,
              },
            }
            for (let index = 0; index < totalGareNature.length; index++) {
              
              // amende de pesage
              if (this.amende_pesage.max.montant<totalGareNature[index].amende_pesage) {
                this.amende_pesage.max.gare=totalGareNature[index].gare
                this.amende_pesage.max.montant=totalGareNature[index].amende_pesage
              }
              if (this.amende_pesage.min.montant>totalGareNature[index].amende_pesage) {
                this.amende_pesage.min.gare=totalGareNature[index].gare
                this.amende_pesage.min.montant=totalGareNature[index].amende_pesage
              }
              // frais de pesee
              if (this.frais_pesee.max.montant<totalGareNature[index].frais_pesee) {
                this.frais_pesee.max.gare=totalGareNature[index].gare
                this.frais_pesee.max.montant=totalGareNature[index].frais_pesee
              }
              if (this.frais_pesee.min.montant>totalGareNature[index].frais_pesee) {
                this.frais_pesee.min.gare=totalGareNature[index].gare
                this.frais_pesee.min.montant=totalGareNature[index].frais_pesee
              }
              // pesée cimentier
              if (this.pesee_cimentier.max.montant<totalGareNature[index].pesee_cimentier) {
                this.pesee_cimentier.max.gare=totalGareNature[index].gare
                this.pesee_cimentier.max.montant=totalGareNature[index].pesee_cimentier
              }
              if (this.pesee_cimentier.min.montant>totalGareNature[index].pesee_cimentier) {
                this.pesee_cimentier.min.gare=totalGareNature[index].gare
                this.pesee_cimentier.min.montant=totalGareNature[index].pesee_cimentier
              }               
              
            }
          }
        } else {
          this.gareData=null
          this.gareData = this.recettePesageG["tableau"]
          this.monthTotal = []
          this.month= [
            { mois: "Janv.", total:[0]},
            { mois: "Fev.", total:[0]},
            { mois: "Mars", total:[0]},
            { mois: "Avr.", total:[0]},
            { mois: "Mai.", total:[0]},
            { mois: "Juin", total:[0]},
            { mois: "Juil.", total:[0]},
            { mois: "Août.", total:[0]},
            { mois: "Sept.", total:[0]},
            { mois: "Oct.", total:[0]},
            { mois: "Nov.", total:[0]},
            { mois: "Dec.", total:[0]}
          ]
          this.moisMax.mois=this.month[0].mois
          this.moisMax.montant=this.month[0].total
          this.moisMin.mois=this.month[0].mois
          this.moisMin.montant=this.month[0].total
          this.chartDataLineChart={
            labels: [],
            datasets: [],
          }
          color=[
            "#083291",
            "#910856",
            "#910818",
            "#917a08",
            "#c4b3f5",
            "#a829e3",
            "#292fe3",
            "#29b5e3",
            "#29e392",
            "#1e3b2e",
            "#3b2a1e",
            "#331e3b",
            "#806ca1",
            "#6ca19d",
            "#74a16c",
            "#a1816c",
            "#f2b8b6",
            "#b6f2b8",
            "#b3e6f5",
            "#911108",
            "#fa6f05",
            "#fafa05",
            "#63fa05",
          ]
          this.chartDataBarChart2 = {labels: [], datasets: []}

          for (let pointer = 0; pointer < this.gareData.length; pointer++) {
            this.monthTotal.push(0)
            this.chartDataLineChart.datasets.push({
              label: "Recette pesage "+this.gareData[pointer].gare,
              data: [],
              backgroundColor: [],
              borderColor:"",
              fill: false,
              hoverOffset: 4,
            })
            this.chartDataBarChart2.datasets.push(
              {
                label: "Amende de pesage "+this.gareData[pointer].gare,
                data: [],
                backgroundColor: [],
                hoverOffset: 4,
                // stack:'Stack '+pointer,
              },
              {
                label: "Frais de pesée "+this.gareData[pointer].gare,
                data: [],
                backgroundColor: [],
                hoverOffset: 4,
                // stack:'Stack '+pointer,
              },
              {
                label: "Pesée cimentier "+this.gareData[pointer].gare,
                data: [],
                backgroundColor: [],
                hoverOffset: 4,
                // stack:'Stack '+pointer,
              }
            )
          }

          this.monthTotal.push(0)  
          
          for (let index = 0; index < this.month.length; index++) {
            for (let pointer = 0; pointer < this.gareData.length; pointer++) {
              this.month[index].total[pointer]=0
            } 
            this.month[index].total.push(0)
            this.chartDataLineChart.labels.push(this.month[index].mois)
            this.chartDataBarChart2.labels.push(this.month[index].mois)
          }

          position=0
          totalGareNature=[]
          for (let pointer = 0; pointer < this.gareData.length; pointer++) {
            totalGareNature.push({
              gare:this.gareData[pointer].gare,
              amende_pesage:0,
              frais_pesee:0,
              pesee_cimentier:0,
            })
            for (let index = 0; index < this.month.length; index++) {
              this.month[index].total[pointer]+=
                this.gareData[pointer].pesee_cimentier[index] +
                this.gareData[pointer].frais_pesee[index] +
                this.gareData[pointer].amende_pesage[index] //+

              this.chartDataLineChart.datasets[pointer].data[index]=
                this.gareData[pointer].pesee_cimentier[index] +
                this.gareData[pointer].frais_pesee[index] +
                this.gareData[pointer].amende_pesage[index]

              // TOTAL de toutes les gares par mois
              this.month[index].total[this.gareData.length]+=
                this.gareData[pointer].pesee_cimentier[index] +
                this.gareData[pointer].frais_pesee[index] +
                this.gareData[pointer].amende_pesage[index]
              //Total de toutes les nature par gare
              totalGareNature[pointer].amende_pesage+=this.gareData[pointer].amende_pesage[index]
              totalGareNature[pointer].frais_pesee+=this.gareData[pointer].frais_pesee[index]
              totalGareNature[pointer].pesee_cimentier+=this.gareData[pointer].pesee_cimentier[index]
              if (position+1<this.gareData.length) {
                this.chartDataBarChart2.datasets[position].data[index]=this.gareData[pointer].amende_pesage[index]
                this.chartDataBarChart2.datasets[position+1].data[index]=this.gareData[pointer].frais_pesee[index]
                this.chartDataBarChart2.datasets[position+2].data[index]=this.gareData[pointer].pesee_cimentier[index]
                this.chartDataBarChart2.datasets[position].backgroundColor.push(color[pointer])
                this.chartDataBarChart2.datasets[position+1].backgroundColor.push(color[pointer])
                this.chartDataBarChart2.datasets[position+2].backgroundColor.push(color[pointer])
              }
                  
              this.monthTotal[pointer] += this.month[index].total[pointer]
              //TOTAL DES TOTAUX DE TOUS LES MOIS PAR GARE
              this.monthTotal[this.gareData.length] += this.month[index].total[pointer]
              this.chartDataLineChart.datasets[pointer].backgroundColor.push(color[pointer])
            }
            this.chartDataLineChart.datasets[pointer].borderColor = color[pointer]
            console.log(this.chartDataLineChart)
            position=position+3
            this.heavyDataLoaded=true
          }
          
          // Initialisation des objets pour obtenir le mois le plus rentable et le mois le moins rentable d'unegare
          totalGareMois=[]
          this.totalAnnee=0
          for (let index = 0; index < this.month.length; index++) {
            totalGareMois.push({mois:this.month[index].mois,montant:0})
            totalMois = 0
            for (let jindex = 0; jindex < this.month[index].total.length-1; jindex++) {
              totalMois += this.month[index].total[jindex]
              this.totalAnnee += this.month[index].total[jindex]
            }
            totalGareMois[index].montant=totalMois
          }
          // console.log(totalGareMois)
          this.moisMax.mois = totalGareMois[0].mois
          this.moisMax.montant = totalGareMois[0].montant
          this.moisMin.mois = totalGareMois[0].mois
          this.moisMin.montant = totalGareMois[0].montant
          
          for (let index = 0; index < totalGareMois.length; index++) {

            if (totalGareMois[index].montant > this.moisMax.montant) {//Determination du mois le plus rentable
              this.moisMax.montant=totalGareMois[index].montant
              this.moisMax.mois=totalGareMois[index].mois
            }
            if (totalGareMois[index].montant < this.moisMin.montant) {//Determination du mois le moins rentable
              this.moisMin.montant=totalGareMois[index].montant
              this.moisMin.mois=totalGareMois[index].mois
            }
          }
          this.moisMax.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le plus rentable
            ((this.moisMax.montant*100)/this.totalAnnee) 
              * 100) / 100
          this.moisMin.pourcentage = Math.round(//Calcul de la proportion en pourcentage du mois le moinsrentable
            ((this.moisMin.montant*100)/this.totalAnnee) 
              * 100) / 100

          // Determination de la gare qui rapporte le plus par carte
          this.amende_pesage={//initialisation de l'objet
            max:{
              montant: totalGareNature[0].amende_pesage,
              gare:totalGareNature[0].gare,
            },
            min:{
              montant: totalGareNature[0].amende_pesage,
              gare:totalGareNature[0].gare,
            },
          }
          this.frais_pesee={//initialisation de l'objet
            max:{
              montant: totalGareNature[0].frais_pesee,
              gare:totalGareNature[0].gare,
            },
            min:{
              montant: totalGareNature[0].frais_pesee,
              gare:totalGareNature[0].gare,
            },
          }
          this.pesee_cimentier={//initialisation de l'objet
            max:{
              montant: totalGareNature[0].pesee_cimentier,
              gare:totalGareNature[0].gare,
            },
            min:{
              montant: totalGareNature[0].pesee_cimentier,
              gare:totalGareNature[0].gare,
            },
          }
          for (let index = 0; index < totalGareNature.length; index++) {
            
            // amende de pesage
            if (this.amende_pesage.max.montant<totalGareNature[index].amende_pesage) {
              this.amende_pesage.max.gare=totalGareNature[index].gare
              this.amende_pesage.max.montant=totalGareNature[index].amende_pesage
            }
            if (this.amende_pesage.min.montant>totalGareNature[index].amende_pesage) {
              this.amende_pesage.min.gare=totalGareNature[index].gare
              this.amende_pesage.min.montant=totalGareNature[index].amende_pesage
            }
            // frais de pesee
            if (this.frais_pesee.max.montant<totalGareNature[index].frais_pesee) {
              this.frais_pesee.max.gare=totalGareNature[index].gare
              this.frais_pesee.max.montant=totalGareNature[index].frais_pesee
            }
            if (this.frais_pesee.min.montant>totalGareNature[index].frais_pesee) {
              this.frais_pesee.min.gare=totalGareNature[index].gare
              this.frais_pesee.min.montant=totalGareNature[index].frais_pesee
            }
            // pesée cimentier
            if (this.pesee_cimentier.max.montant<totalGareNature[index].pesee_cimentier) {
              this.pesee_cimentier.max.gare=totalGareNature[index].gare
              this.pesee_cimentier.max.montant=totalGareNature[index].pesee_cimentier
            }
            if (this.pesee_cimentier.min.montant>totalGareNature[index].pesee_cimentier) {
              this.pesee_cimentier.min.gare=totalGareNature[index].gare
              this.pesee_cimentier.min.montant=totalGareNature[index].pesee_cimentier
            }               
            
          }
        }
      }
    },

    redevancePesage() {
      if (this.redevancePesage["tableau"]) {
        this.yearData = this.redevancePesage
        this.total = {
          recette:0,
          amende_pesage:0,
          frais_pesee:0,
          pesee_cimentier:0,
        }
        this.listGare=[]

        this.gareMax.montant = this.yearData.tableau[0].recettes
        this.gareMax.gare = this.yearData.tableau[0].gare
        this.gareMin.montant = this.yearData.tableau[0].recettes
        this.gareMin.gare = this.yearData.tableau[0].gare
        // console.log(this.gareMax)
        // console.log(this.gareMin)
        this.yearData.tableau.forEach((recette) => {
          this.total.recette += Number(recette.recettes)
          this.total.amende_pesage += Number(recette.amende_pesage)
          this.total.frais_pesee += Number(recette.frais_pesee)
          this.total.pesee_cimentier += Number(recette.pesee_cimentier)
          this.listGare.push(recette.gare)
          if (recette.recettes > this.gareMax.montant) {
            this.gareMax.montant = Number(recette.recettes)
            this.gareMax.gare = recette.gare
          }
          if (recette.recettes < this.gareMin.montant) {
            this.gareMin.montant = Number(recette.recettes)
            this.gareMin.gare = recette.gare
          }
        })
        this.gareRanking=[]
        for (let index = 0; index < this.yearData.tableau.length; index++) {
          this.gareRanking.push({rang:index,recette:this.yearData.tableau[index].recettes,gare:this.yearData.tableau[index].gare})
        }
        this.gareRanking = this.gareRanking.sort((a,b)=>b.recette - a.recette)
        for (let index = 0; index < this.gareRanking.length; index++) {
          if (this.gare == this.gareRanking[index].gare) {
            this.garePosition={rang: index+1,gare:this.gare,recette:this.gareRanking[index].recette,pourcentage:0}
          }
        }
        this.listGare.push("CONSOLIDEE")
        this.listGare.push("TOUTES")
        if (this.total.recette - this.yearData.budget_cible > 0) {
          this.color = "good-news"
        } else {
          this.color = "bad-news"
        }
        if (window.innerWidth > 1400) {
          this.width = "col-md-4 mx-auto"
        }
        this.addColor(this.yearData.graphique.length)

        this.yearData.graphique.forEach((graphique) => {
          if (this.gare==graphique.gare) {
            this.garePosition.pourcentage = Math.round(Number(graphique.pourcentage) * 100) / 100
          }
          if (graphique.gare == this.gareMax.gare) {
            this.gareMax.pourcentage = Math.round(Number(graphique.pourcentage) * 100) / 100
          }
          if (graphique.gare == this.gareMin.gare) {
            this.gareMin.pourcentage = Math.round(Number(graphique.pourcentage) * 100) / 100
          }
          this.chartDataPieChart.datasets[0].data.push(
            Math.round(Number(graphique.pourcentage) * 100) / 100
          )
          this.chartDataPieChart.labels.push(
            graphique.gare +
              " (" +
              Math.round(Number(graphique.pourcentage) * 100) / 100 +
              "%)"
          )
        })
        
      }
    },
    // changeYear(){
    //   if (this.year) {
    //     // this.year=this.extYear
    //     this.redevances=null
    //     this.setRedevancePesage("")
    //     this.chartData.datasets[0].data = []
    //     this.chartData.labels=[]
    //     this.chartData.datasets[0].backgroundColor=[]
    //     this.RedevancePesage({annee:this.year})
    //   }
    // }
  },
  computed: {
    ...mapGetters(["arRecetteG", "arRecette", "redevancePesage", "recettePesageG"]),
  },
  methods: {
    ...mapActions(["RedevancePesage", "RecettePesageG"]),
    ...mapMutations(["setRedevancePesage", "setRecettePesageG"]),
    changeGare(gare) {
      this.redevances=null
      this.axe=''
      this.gare=''
      this.heavyDataLoaded=false
      this.dataLoaded=false
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setRedevancePesage("")
      this.chartDataPieChart.datasets[0].data = []
      this.chartDataPieChart.labels=[]
      this.chartDataPieChart.datasets[0].backgroundColor=[]
      this.totalNatureRecette={
        position:[
          {nature:"",montant:0},
          {nature:"",montant:0},
          {nature:"",montant:0},
        ],
        moisMax:{montant:0,mois:""},
        moisMin:{montant:0,mois:""},
        pourcentage:"",
      }
      this.chartDataLineChart.datasets = [{
        label: "Recette pesage ",
        data: [],
        backgroundColor: [],
        fill: true,
        hoverOffset: 4,
      }]
      this.chartDataLineChart.labels=[]
      this.chartDataBarChart= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.chartDataBarChart2= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.yearData=null
      this.gareData=null
      this.gare=gare
      this.RedevancePesage(this.year)
      this.RecettePesageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, gare: this.gare })
    },
    changeAxe(axe) {
      this.redevances=null
      this.gare=''
      this.axe=''
      this.heavyDataLoaded=false
      this.dataLoaded=false
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setRedevancePesage("")
      this.chartDataPieChart.datasets[0].data = []
      this.chartDataPieChart.labels=[]
      this.chartDataPieChart.datasets[0].backgroundColor=[]
      this.totalNatureRecette={
        position:[
          {nature:"",montant:0},
          {nature:"",montant:0},
          {nature:"",montant:0},
        ],
        moisMax:{montant:0,mois:""},
        moisMin:{montant:0,mois:""},
        pourcentage:"",
      }
      this.chartDataLineChart.datasets = [{
        label: "Recette pesage ",
        data: [],
        backgroundColor: [],
        fill: true,
        hoverOffset: 4,
      }]
      this.chartDataBarChart= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.chartDataBarChart2= {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.chartDataLineChart.labels=[]
      this.yearData=null
      this.gareData=null
      this.axe=axe
      this.RedevancePesage(this.year)
      this.RecettePesageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, axe: this.axe })
    },
    changeYear() {
      this.redevances=null
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setRedevancePesage("")
      this.chartDataPieChart.datasets[0].data = []
      this.chartDataPieChart.labels=[]
      this.chartDataPieChart.datasets[0].backgroundColor=[]
      this.chartDataLineChart.datasets[0].data = []
      // this.totalNatureRecette={
      //   position:[
      //     {nature:"",montant:0},
      //     {nature:"",montant:0},
      //     {nature:"",montant:0},
      //   ],
      //   moisMax:{montant:0,mois:""},
      //   moisMin:{montant:0,mois:""},
      //   pourcentage:"",
      // }
      this.chartDataLineChart.datasets = [{
        label: "Recette pesage ",
        data: [],
        backgroundColor: [],
        hoverOffset: 4,
      }]
      this.setRecettePesageG("")
      this.chartDataLineChart.labels=[]
      this.chartDataBarChart = {
        labels: [],
        datasets: [
          {
            label: "Paiement comptant",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
          {
            label: "Paiement par carte",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          }
        ],
      }
      this.heavyDataLoaded=false
      this.dataLoaded=false
      this.yearData=null
      this.gareData=null
      this.RedevancePesage(this.year)
      if (this.gare!=null && this.gare!='') {
        this.RecettePesageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, gare: this.gare })
      }else{
        this.RecettePesageG({ annee_debut: this.annee_debut,annee_fin: this.annee_fin, axe: this.axe })
      }
    },

    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    addColor(long) {
      for (let i = 0; i < long; i++) {
        this.chartDataPieChart.datasets[0].backgroundColor.push(
          this.tableauCouleurs[i]
        )
      }
    },
  },
}
</script>
  